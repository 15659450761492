import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import { getFormattedString } from '@/utilities/mutate';

@Component
export default class CheckboxInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public title!: string;
    private validationArray: ValidationModel[] = [];

    private indeterminate: boolean = true;
    private checkAll: boolean = false;
    private checkedList: LooseObject[] = [];

    protected created() {
        EventBus.$on('requestValue', this.submitValue);
        this.createValidationObject();
        this.setInitialObjectValues();
        this.props.optionNames = [];
        this.props.optionValues = [];
        this.props.defaultValues = [];
        for (const index in this.props.options) {
            this.props.optionNames[index] = this.props.options[index].title;
            this.props.optionValues[index] = this.props.options[index].value;
            if (this.props.options[index]?.default) {
                this.props.defaultValues[index] = this.props.options[index].title;
            }
            this.checkedList = this.props.defaultValues;
        }
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private setInitialObjectValues() {
        this.$emit('update:value', this.checkedList);
    }

    private isValueValid() {
        if (this.props.required && this.checkedList.length === 0) {
          return false;
        }
        return true;
    }

    private submitValue() {
        if (this.isValueValid()) {
            for (const item in this.checkedList) {
                for (const index in this.props.optionNames){
                    if (this.checkedList[item] === this.props.optionNames[index]) {
                        this.checkedList[item] = this.props.optionValues[index];
                        break;
                    }
                }
            }
            EventBus.$emit('submitValue', this.checkedList.join(',') , this.props.applicationField, this.props.label);
        }
    }

    private createValidationObject() {
        this.validationArray = [
            {
                required: this.props.required,
                message: this.props.requiredMessage || 'Please select at least one option from the list',
                trigger: 'blur',
            },
        ];
    }

    private onChange(checkedList: any) {
          this.indeterminate = !!checkedList.length && checkedList.length < this.props.optionNames.length;
          this.checkAll = checkedList.length === this.props.optionNames.length;
          this.$emit('update:value', this.checkedList);
        }

        private onCheckAllChange(e: any) {
          Object.assign(this, {
            checkedList: e.target.checked ? this.props.optionNames : [],
            indeterminate: false,
            checkAll: e.target.checked,
          });
          this.$emit('update:value', this.checkedList);
        }
}
