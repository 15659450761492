import { Component, Vue, Ref } from 'vue-property-decorator';
import FormSection from '../FormSection/FormSection.vue';
import { EventBus } from '@/utilities/eventBus/EventBus';

@Component({
    components: {
        FormSection,
    },
})
export default class Layout extends Vue {
    @Ref('header') private header!: HTMLElement;

    public loginRequired(): boolean {
        return this.$msal.requiresLogin;
    }

    public loggedInName(): string | undefined {
        return this.$msal.account ? this.$msal.account.name : undefined;
    }

    public appLoaded(): boolean {
        return !!(this.$store.state.applicationIdentifier || this.$store.state.applicantIdentifier);
    }

    public signOut(): void {
        this.$store.commit('updateSessionIdentifier', undefined);
        this.$store.commit('updateApplicationIdentifier', undefined);
        this.$store.commit('updateApplicantIdentifier', undefined);
        this.$msal.signOut();
    }

    public closeApp() {
        this.$store.commit('updateFormHistory', []);
        this.$store.commit('updateMessages', []);
        this.$store.commit('updateApplicantIdentifier', undefined);
        this.$store.commit('updateApplicationIdentifier', undefined);
        EventBus.$emit('refresh');
    }

    public isAuthenticated() {
        return this.$msal.isAuthenticated;
    }

    protected mounted() {
        window.addEventListener('scroll', this.handleScroll);
    }

    protected beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    private handleScroll() {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScrollPosition < 0) {
            return;
        } else if (currentScrollPosition >= 0 && currentScrollPosition < 80) {
            this.header.classList.remove('hidden');
        } else if (currentScrollPosition >= 80) {
            this.header.classList.add('hidden');
        }
    }
}
