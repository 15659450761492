import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import { getFormattedString } from '@/utilities/mutate';
import router from '@/router';

@Component
export default class TableInput extends Vue {
    @Prop() public props!: LooseObject;
    private searchText: string = '';
    protected created() {
        /* tslint:disable:no-string-literal */
        if (this.props.actionColumns &&
            this.props.tableColumns[this.props.tableColumns.length - 1]['dataIndex'] !== 'action') {
            this.props.tableColumns[this.props.tableColumns.length] = {
                dataIndex: 'action',
                scopedSlots: {
                    customRender: 'action',
                },
                title: 'Action',
            };
        }
        this.props.tableColumns?.forEach((element: { [x: string]: any; }, index: any) => {
            if (element['searchable']) {
                this.props.tableColumns[index] = {
                    title: element['title'],
                    dataIndex: element['dataIndex'],
                    key: element['dataIndex'],
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender',
                    },
                    onFilter: (value: string, record: { [x: string]: { toString: () => string; }; }) =>
                        record[element['dataIndex']]
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase()),
                };
            }
            if (element['sortable']) {
                this.props.tableColumns[index]['sorter'] = function(a: any, b: any) {
                    return a[element['dataIndex']].localeCompare(b[element['dataIndex']]);
                };

                if (element['defaultOrder']) {
                    this.props.tableColumns[index]['defaultSortOrder'] = element['defaultOrder'].toString();
                }
            }
        });
        this.props.tableData?.forEach((element: { [x: string]: any; }, index: any) => {
            this.props.tableData[index]['key'] = index + 1;
        });
    }
    private handleSearch(selectedKeys: any[], confirm: () => void, dataIndex: any) {
        confirm();
        this.props.searchText = selectedKeys[0];
        this.props.searchedColumn = dataIndex;
    }

    private handleReset(clearFilters: () => void) {
        clearFilters();
        this.props.searchText = '';
    }

    private resolveAction(action: string, record: LooseObject) {
        const match = action.match(new RegExp(/(\{{[^{}]*\}})/g));
        match?.forEach((element) => {
            action = action.replace(element, record[element.substring(2, element.length - 2)]);
        });
        window.location.href = action;
        window.location.reload();
    }

    private getWindow() {
        if (window.innerWidth < 600) {
            return true;
        } else {
            return false;
        }
    }
}
