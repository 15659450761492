import { Prop, Component, Vue, Ref } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import MenuModel from '@/models/Objects/MenuModel';
import MyMenuItem from '@/models/Objects/MenuItem';
import store from '@/store';
import Home from '@/views/Home/Home';

@Component
export default class Menu extends Vue {
    @Ref('Menu') private Menu?: HTMLElement;
    private collapsed: boolean = false;
    private home = new Home();
    @Prop() private menu?: MenuModel;

    protected toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }

    protected mounted() {
        EventBus.$on('update-menu', (menu?: any) => {
            this.menu = menu;
        });
    }

    private resolveWorkflow(workflow: string) {
        store.commit('updateWorkflow', workflow);
        EventBus.$emit('resolveWorkflow');
    }
}
